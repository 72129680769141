import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col, Button } from 'react-bootstrap'
import Truncate from 'react-truncate'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/pro-solid-svg-icons"
import FacebookIcon from "./FacebookIcon"
import TVMHCLogoSm from "./TVMHCLogoSm"

const FacebookFeed = (props) => {
  const data = useStaticQuery(graphql`
    {
      allFacebookPosts(limit: 3, sort: {fields: created_time, order: DESC}) {
        edges {
          node {
            id
            created_time(formatString: "MMMM Do, YYYY")
            message
            permalink_url
            internal {
              content
            }
            full_picture
          }
        }
      }
    }
  `)

const posts = data.allFacebookPosts.edges;

  return (
    <Container
    fluid
    style={{
      backgroundColor: `${props.backgroundColor}`,
    }}
  >
       <Row>
          <Col>
            <h2 className="sectionTitle">Our Latest News</h2>
          </Col>
        </Row>
      <Container >
      <Row>
    {posts.map(({ node }) => {
        return (
        <Col sm={4}
            className="mb-4"
            key={node.id}
            message={node.message}
            style={{
                display: 'flex',
                flexDirection: 'column',
                
            }}
        >
            <div style={{marginBottom: '10px', textAlign: 'center'}}>
                {node.full_picture ? (
                    <img src={node.full_picture} alt="" style={{maxWidth: '100%'}} className="rounded"/>
                ) : <div><TVMHCLogoSm /></div>
                }
                
            </div>
            <div style={{
                position: 'absolute',
                top: '-10px',
                right: '10px'

            }}>
                <FacebookIcon />
            </div>
            <div>
                <p className="muted">Posted {node.created_time}</p>
            </div>
            <div><p style={{color: '#434081'}}>
                <Truncate lines={6}>
                {node.message}
                </Truncate></p></div>
            <div style={{textAlign: 'right'}}>
            
            <a href={node.permalink_url} target="_blank" rel="noopener noreferrer">
                <Button className="purpleButton">Read more <FontAwesomeIcon
                        icon={faAngleRight}
                        aria-hidden="true"
            
                    /></Button>
            </a></div>
        </Col>
        )
})}
    </Row>
    </Container>
    </Container>
  )
}

export default FacebookFeed