import React from "react";
import styled from "styled-components"

const Logo = styled.svg `
  width: 32px;
  height: 32px;
  
  .cls-1 {
        fill: #043b87;
      }

      .cls-2 {
        fill: #fff;
      }
`;

const FacebookIcon = () => {
    return (

<Logo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <defs>
    <style>

    </style>
  </defs>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <path class="cls-1" d="M12,0A12,12,0,1,0,24,12,12.00053,12.00053,0,0,0,12,0Z"/>
      <path class="cls-2" d="M16.27993,6.53722H14.43624c-.73474,0-.8877.30182-.8877,1.06251V9.26861h2.73139L15.9945,12h-2.446v9.55986H9.45146V12H6.72007V9.26861H9.45146v-3.152c0-2.41592,1.27146-3.67645,4.13669-3.67645h2.69178Z"/>
    </g>
  </g>
</Logo>

    )
  }

  export default FacebookIcon
